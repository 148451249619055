import { render, staticRenderFns } from "./InvoiceAddBlank.vue?vue&type=template&id=82804c8e&scoped=true"
import script from "./InvoiceAddBlank.vue?vue&type=script&lang=js"
export * from "./InvoiceAddBlank.vue?vue&type=script&lang=js"
import style0 from "./InvoiceAddBlank.vue?vue&type=style&index=0&id=82804c8e&prod&lang=scss"
import style1 from "./InvoiceAddBlank.vue?vue&type=style&index=1&id=82804c8e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82804c8e",
  null
  
)

export default component.exports