<template>
    <section class="invoice-add-wrapper">
      <b-row
        class="invoice-add"
      >
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="9" md="8">
          <b-form @submit.prevent>
            <b-card no-body class="invoice-preview-card">
  
              <!-- Invoice Client & Payment Details -->
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing justify-content-start">
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="6">
                    <h6 class="mb-2">Invoice To:</h6>
                    <b-form-group label="Client Name *" label-for="client-name">
                      <b-form-input
                        id="client-name"
                        rules="required"
                        v-model="invoiceData.name"
                        placeholder="Enter Name"
                      />
                    </b-form-group>
                    <b-form-group label="Client Email" label-for="client-email">
                      <b-form-input
                        id="client-email"
                        v-model="invoiceData.email"
                        placeholder="Enter Email"
                      />
                    </b-form-group>
                    <b-form-group label="Client Mobile" label-for="client-mobile">
                      <b-form-input
                        id="client-mobile"
                        v-model="invoiceData.mobile_no"
                        placeholder="Enter Mobile"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Client Address"
                      label-for="client-address"
                    >
                      <b-form-input
                        id="client-address"
                        v-model="invoiceData.address"
                        placeholder="Enter Address"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
  
              <!-- Subject -->
              <b-card-body class="invoice-padding py-0">
                <b-form-group label="Subject: *" label-for="subject">
                  <b-form-textarea
                    id="subject"
                    rules="required"
                    v-model="invoiceData.subject"
                    placeholder="Enter Subject"
                  />
                </b-form-group>
  
                <!-- <span class="font-weight-bold">Subject: (Optional)</span>
                <b-form-textarea v-model="invoiceData.subject" /> -->
              </b-card-body>
  
              <hr class="invoice-spacing" />
  
              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{ height: trHeight }"
                >
                  <b-row
                    v-for="(item, index) in invoiceData.products"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex pb-75">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col cols="8" md="10"> Item </b-col>
                          <b-col cols="4" md="2"> Cost (₹) </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>
  
                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col cols="8" md="10">
                            <label class="d-inline d-lg-none"
                              >Item Description</label
                            >
                            <b-form-input
                              v-model="item.item_name"
                              type="text"
                              placeholder="Item Description"
                            />
                          </b-col>
                          <b-col cols="4" md="2">
                            <label class="d-inline d-lg-none">Cost</label>
                            <b-form-input
                              v-model="item.price"
                              type="number"
                              @change="onPriceChange"
                            />
                          </b-col>
                        </b-row>
                        <div
                          class="d-flex flex-column justify-content-center border-left py-50 px-25"
                        >
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
  
                <b-row class="justify-content-end">
                  <b-col cols="4">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      @click="addNewItemInItemForm"
                    >
                      Add Item
                    </b-button>
                  </b-col>
                  <!-- Col: Total -->
                  <b-col
                    cols="8"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Total:</p>
                        <p class="invoice-total-amount">₹ {{ total }}</p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
  
              <!-- Spacer -->
              <hr class="invoice-spacing" />
  
              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Note: (Optional)</span>
                <b-form-textarea v-model="invoiceData.note" />
              </b-card-body>
            </b-card>
          </b-form>
        </b-col>
  
        <!-- Right Col: Card -->
        <b-col cols="12" md="4" xl="3" class="invoice-actions mt-0">
          <!-- Action Buttons -->
          <b-card>
            <!-- Button: Send Invoice -->
            <b-overlay
              :show="$store.state.invoiceStore.isInvoiceLoading"
              rounded="sm"
              variant="transparent"
              opacity="0.5"
              blur="2px"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="saveInvoice"
              >
                Save Invoice
              </b-button>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </template>
  
  <script>
  import Logo from '@core/layouts/components/Logo.vue'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { heightTransition } from '@core/mixins/ui/transition'
  import Ripple from 'vue-ripple-directive'
  import store from '@/store'
  import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    VBToggle,
    BOverlay
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import flatPickr from 'vue-flatpickr-component'
  import invoiceStoreModule from '../invoiceStoreModule'
  import router from '@/router'
  import axios from 'axios'
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardBody,
      BButton,
      BCardText,
      BForm,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BInputGroupPrepend,
      BFormTextarea,
      BFormCheckbox,
      BPopover,
      flatPickr,
      vSelect,
      Logo,
      BOverlay
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle
    },
    mixins: [heightTransition],
    mounted() {
      this.initTrHeight()
    },
    created() {
      window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
      window.removeEventListener('resize', this.initTrHeight)
    },
    data() {
      return {
        total: 0
      }
    },
    methods: {
      onPriceChange() {
        let sum = 0
        for (let i = 0; i < this.invoiceData.products.length; i++) {
          sum += parseFloat(this.invoiceData.products[i].price)
        }
        this.total = sum
      },
      addNewItemInItemForm() {
        this.$refs.form.style.overflow = 'hidden'
  
        this.invoiceData.products.push(
          JSON.parse(JSON.stringify(this.itemFormBlankItem))
        )
  
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      },
      removeItem(index) {
        this.invoiceData.products.splice(index, 1)
        this.onPriceChange()
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      },
      initTrHeight() {
        this.trSetHeight(null)
        this.$nextTick(() => {
          // this.trSetHeight(this.$refs.form.scrollHeight)
        })
      },
      saveInvoice() {
        this.$store
          .dispatch('invoiceStore/saveInvoice', {
            ctx: this,
            payload: this.invoiceData
          })
          .then((result) => {
            console.log(result.data.s3url)
            // this.refetchData()
            this.downloadInvoice({
              url: result.data.s3url,
              invoice_num: result.data.invoice_num,
            })
            const type = router.currentRoute.params.type
            router.push({
                name: 'invoice-list'
              })
          })
      },
      downloadAs(url, name) {
        axios
          .get(url, {
            headers: {
              'Content-Type': 'application/octet-stream'
            },
            responseType: 'blob'
          })
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.setAttribute('download', name)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
          })
          .catch((err) => {
            console.log('error', err)
          })
      },
  
      downloadInvoice({ url, invoice_num, unique_id }) {
        let href = url.replace(
          'http://s3.mymunshi.live',
          'https://app.mymunshi.in/exports'
        )
        this.downloadAs(href, `${invoice_num}.pdf`)
      }
    },

    setup() {
      const itemFormBlankItem = {
        price: 0,
        item_name: ''
      }
  
      let userData = JSON.parse(localStorage.getItem('userData'))
  
      const invoiceData = ref({
        name: '',
        user_id: userData._id,
        email: '',
        mobile_no: '',
        address: '',
        products: [JSON.parse(JSON.stringify(itemFormBlankItem))],
        note: '',
        subject: ''
      })
  
      return {
        invoiceData,
        itemFormBlankItem
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .invoice-add-wrapper {
    .add-new-client-header {
      padding: $options-padding-y $options-padding-x;
      color: $success;
  
      &:hover {
        background-color: rgba($success, 0.12);
      }
    }
  }
  </style>
  
  <style lang="scss" scoped>
  @import '~@core/scss/base/pages/app-invoice.scss';
  @import '~@core/scss/base/components/variables-dark';
  
  .form-item-section {
    background-color: $product-details-bg;
  }
  
  .form-item-action-col {
    width: 27px;
  }
  
  .repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
  }
  
  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;
  
      .dark-layout & {
        background-color: unset;
      }
    }
  }
  
  .dark-layout {
    .form-item-section {
      background-color: $theme-dark-body-bg;
  
      .row .border {
        background-color: $theme-dark-card-bg;
      }
    }
  }
  </style>
  